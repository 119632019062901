import React from 'react'
import { Helmet } from 'react-helmet-async'
import './Privacy.css'

export const Privacy = () => {
    return (
        <div>
            <Helmet>
                <title>Privay Policy : igenai.quantumorion.com</title>

                <meta name="description" content="Welcome to IgenAI.This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website igenai, use our AI text creation and image generation services, or interact with us in any other way." />
            </Helmet>
            <div className='privacy'>
                <div className='information'>
                    <h2>Privacy Policy</h2>
                    <p>Welcome to IgenAI.This Privacy Policy explains how we collect, use, disclose, and
                        safeguard your information when you visit our website igenai, use our AI text creation and image
                        generation services, or interact with us in any other way.</p>
                </div>

                <div className='iwc'>
                    <p>We may collect information about you in a variety of ways. The information we may collect on the Site includes:</p>
                    <h3>Personal Data</h3>
                    <p>Personally identifiable information, such as your name, email address, and any other information you voluntarily provide to us when you register on the Site, use our services, or contact us.</p>
                    <h3>Generated Content Data</h3>
                    <p>The text inputs and outputs, images generated, and other content you create or modify using our services. This includes metadata associated with these files.</p>
                    <h3>Derivative Data</h3>
                    <p>Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system,your access times, and the pages you have viewed directly before and after accessing the Site.</p>
                    <h3>Usage Data</h3>
                    <p>Details of your use of our Site and services including, but not limited to, traffic data, location data, logs, and other communication data and the resources that you access and use on the Site.</p>
                    <h3>Device Data</h3>
                    <p><span className='error'>Information about the device you are using, such as the device's unique ID, operating system, and browser type.</span></p>

                </div>

                <div className='uinfo'>
                    <h4>How We Use Your Information</h4>
                    <p>We use the information we collect to:</p>
                    <ul>
                        <li>To operate, maintain, and improve our Site and services.</li>
                        <li>To process and manage your use of our services.</li>
                        <li>To analyze the generated content for improving our AI models and services.</li>
                        <li>To communicate with you, including responding to your comments, questions, and requests.</li>
                        <li>To send you technical notices, updates, security alerts, and support and administrative messages.</li>
                        <li>To monitor and analyze trends, usage, and activities in connection with our Site.</li>
                        <li>To personalize and improve the Site and provide advertisements, content, or features that match user profiles or interests.</li>

                    </ul>

                </div>

                <div className='dinfo'>
                    <h3>Disclosure of Your Information</h3>
                    <p>We may share information we have collected about you in certain situations. Your information may be disclosed as follows:</p>
                    <h3>By Law or to Protect Rights</h3>
                    <p>If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.</p>
                    <h3>Business Transfers</h3>
                    <p>We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</p>
                    <h3>Affiliates</h3>
                    <p>We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.</p>
                    <h3>Service Providers</h3>
                    <p>We may share your information with third-party vendors, service providers, contractors, or agents who perform services for us or on our behalf and require access to such information to do that work. These include payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.</p>
                    <h3>Other Third Parties</h3>
                    <p>We may share your information with advertisers and investors for the purpose of conducting general business analysis. We may also share your information with such third parties for marketing purposes, as permitted by law.</p>
                    <h3>Security of Your Information</h3>
                    <p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.</p>
                    <h3>Policy for Children</h3>
                    <p>We do not knowingly solicit information from or market to children under the age of 13. If we learn we have collected personal information from a child under age 13 without verification of parental consent, we will delete that information as quickly as possible. If you believe we might have any information from or about a child under 13, please contact us at theorioncompanyinc@gmail.com</p>
                    <h3>Changes to This Privacy Policy</h3>
                    <p>We may update this Privacy Policy from time to time in order to reflect changes to our practices or for other operational, legal, or regulatory reasons. We will notify you of any changes by posting the new Privacy Policy on our Site. You are advised to review this Privacy Policy periodically for any changes.</p>

                </div>


            </div>


        </div>

    )
};

export default Privacy;
