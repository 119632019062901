import React from 'react'
import './Contactus.css'
import { Helmet } from 'react-helmet-async'
export const Contactus = () => {
  return (
    <>
      <Helmet>
        <title>Contact us : igenai.quantumorion.com</title>
        <meta name="description" content="Welcome to IgenAI, we serve services like AI tools and many more. We started in 2024,we have been dedicated to providing serives to the people. At Igenai,our mission is to provide services based on AI like providing free AI images." />
        
      </Helmet>
      <div className='headings'>
        <h2>Contact Us</h2>
        <p>We value your feedback and inquiries. Please use the following information to get in touch with us:</p>
      </div>

      <div className='details'>
        <p>For general queries, comments, or feedback, please email us at:</p>

        <div className='link'>
          <a href='mailto:theorioncompanyinc@gmail.com'>theorioncompanyinc@gmail.com</a>
        </div>
      </div>
    </>
  )
}


