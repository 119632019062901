import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'


export const Footer = () => {
  return (
    <footer>
      <div className='row'>
        <div className='col'>
          <h3>Company</h3>
          <ul>
            <li><Link to= "/About">About Us</Link></li>
            {/* <li>Careers</li>
            <li>Press</li>
            <li>Blog</li> */}
          </ul>
        </div>
        <div className='col'>
          <h3>Legal</h3>
          <ul>
          <li><Link to= "/Privacy">Privacy Policy</Link></li>
          <li><Link to= "/Terms">Terms of Service</Link></li>
          <li><Link to= "/Cookie">Cookie Policy</Link></li>
          </ul>
        </div>
        <div className='col'>
          <h3>Help</h3>
          <ul>
            {/* <li>Support</li>
            <li>FAQs</li> */}
            <li><Link to= "/Contactus">Contact Us</Link></li>
          </ul>
        </div>
        <div className='col'>
          <h3>Follow Us</h3>
          <ul>
            {/* <li>Facebook</li>
            <li>Twitter</li> */}
            <li>Instagram</li>
            {/* <li>LinkedIn</li> */}
          </ul>
        </div>
      </div>
      
      <div className='copyright'>
        <p>&copy; 2024 theorioncompanyinc. All rights reserved.</p>
      </div>
    </footer>
  )
}
