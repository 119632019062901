import React, { useEffect } from 'react';
import logo from './logo.svg';
import { Header } from './MyComponents/Header';
import { Footer } from './MyComponents/Footer';
import { Dashboard } from './MyComponents/Dashboard';
import { Texttoimage } from './MyComponents/Texttoimage';
import { Gifsearch } from './MyComponents/Gifsearch';
import { About } from './MyComponents/About';
import { HelmetProvider } from 'react-helmet-async';
import {
  BrowserRouter as Router,
  Routes,
  Route,useLocation
} from "react-router-dom";
import './App.css';
import { Contactus } from './MyComponents/Contactus';
import Privacy from './MyComponents/Privacy';
import Terms from './MyComponents/Terms';
import { Cookie } from './MyComponents/Cookie';
import { Topscroll } from './MyComponents/Topscroll';

function App() {

  return (
    <HelmetProvider>
    
      <Router>
        <Topscroll/>
        <Header />
        <Routes>
        <Route exact path="/About" element={<About />} />
          <Route exact path="/" element={<Dashboard />} />
          <Route exact path="/texttoimage" element={<Texttoimage />} />
          <Route exact path="/gifsearch" element={<Gifsearch />} />
          <Route exact path="/contactus" element={<Contactus />} />
          <Route exact path="/Privacy" element={<Privacy />} />
          <Route exact path="/Terms" element={<Terms />} />
          <Route exact path="/Cookie" element={<Cookie />} />
        </Routes>
        
        
        <Footer />
       
      </Router>
    
    </HelmetProvider>
  );
}

export default App;
