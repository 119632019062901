import React, { useRef, useState } from 'react';
import './Texttoimage.css';
import tiger from './Assets/tiger.jpeg';
import loadinggif from './Assets/Chunk@1x-4.0s-200px-200px.gif';
import { Helmet } from 'react-helmet-async';
import AdComponent from './AdComponent';
import ThirdAdComponent from './ThirdAdComponent'


const inappropriateKeywords = [
  'nudity', 'porn', 'pornographic', 'sex', 'sexual', 'explicit', 'nsfw', 'nude', 
  'bdsm', 'bondage', 'fetish', 'erotic', 'orgasm', 'anal', 'oral', 'vagina', 'penis', 
  'genital', 'breast', 'boobs', 'nipple', 'butt', 'ass', 'whore', 'slut', 'prostitute', 
  'rape', 'molest','suicide'];

const containsInappropriateContent = (text) => {
  const lowerCaseText = text.toLowerCase();
  return inappropriateKeywords.some(keyword => lowerCaseText.includes(keyword));
};

export const Texttoimage = () => {
  const [imageUrl, setImageUrl] = useState(tiger); // Default to the clock image
  const [loading, setLoading] = useState(false); // To handle the loading state
  const [error, setError] = useState(''); // To handle error messages
  const inputRef = useRef(null);

  const imageGenerator = async () => {
    const prompt = inputRef.current.value.trim();
    if (prompt === "") {
      setError('Please enter a description.');
      return;
    }

    if (containsInappropriateContent(prompt)) {
      setError('Input contains inappropriate content. Please enter a different description.');
      return;
    }

    setLoading(true); // Set loading to true before fetching the image
    setError(''); // Clear any previous errors

    try {
      const response = await fetch(
        "https://api-inference.huggingface.co/models/ZB-Tech/Text-to-Image",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${process.env.REACT_APP_TEXTTOIMAGE_KEY}`,
          },
          body: JSON.stringify({
            inputs: prompt,
            parameters: {
              n: 1,
              size: "512x512"
            },
            options: {
              wait_for_model: true
            }
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error:', errorData);
        setImageUrl(tiger); // Revert to the default image in case of error
        setError('Failed to generate image. Please try again.');
        setLoading(false);
        return;
      }

      const blob = await response.blob();
      const generatedImageUrl = URL.createObjectURL(blob);
      setImageUrl(generatedImageUrl);

    } catch (error) {
      console.error('Error:', error);
      setImageUrl(tiger); // Revert to the default image in case of error
      setError('Failed to generate image. Please try again.');
    } finally {
      setLoading(false); // Set loading to false after fetching the image
    }
  };

  return (
    <>
      <Helmet>
        <title> Free Text to Image AI Generator online </title>

        <meta name="description" content="For all your tasks you can use our online AI picture generator igenai to find the best solutions that will satisfy your creative needs. igenai,Our AI-powered tool is perfect for everyone who creates visual content either a marketer, designer, or just a regular person. By means of this online free AI picture generator igenai you can easily express your ideas in striking visuals that attract people and create an effect with just a few clicks.igenai, our user-friendly interface makes it possible for everyone no matter the technical proficiency to harness the power of our online free AI image generator igenai.. IgenAi , our free AI image generator portrays innumerable styles to satisfy the requirements of your project ranging from animated graphics to realistic photos."/>
      </Helmet>
      <div className='ai-image'>
        <div className='header'>AI Image <span>Generator</span></div>
        
        <div className='search-box'>
          <input
            type="text"
            ref={inputRef}
            className='search-input'
            placeholder='Describe what you want to see'
          />
          
          <div className='generate-btn' onClick={imageGenerator}>
            Generate
            
          </div>
          
          
          
          
          
          
          
          
        </div>
        {error && <p className='error-message'>{error}</p>}

        
       
        <div className='img-loading'>
        
          <div className='image'>
            {loading ? (
              <img className='loader' src={loadinggif} alt="Loading" /> // Display a loading message or spinner while fetching
            ) : (
              <img src={imageUrl} alt="Generated" />
              
            )}
            
          </div>
          
        </div>
        
        
      </div>
      
      
      
    </>
  );
};
