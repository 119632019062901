import React from 'react'
import './Header.css'
import { Link } from 'react-router-dom'

export const Header = () => {
  return (
    <>
    <nav className='main-nav'>
      <div className='logo'>
      <Link to ="/">IGen AI</Link>
      </div>
      
      <div className='menu-link'>
        <ul>
          <li>
            <Link to ="/">Home</Link>
          </li>
          <li>
          <Link to="/about">About</Link>
          </li>
          <li>
            <Link to ="/Terms">Services</Link>
          </li>
          <li>
            <Link to ="/Contactus">Contact</Link>
          </li>
        </ul>
      </div>


    </nav>

   
    </>
  )
}
