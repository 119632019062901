import React from 'react'
import './Dashboard.css'
import { Form, Link } from 'react-router-dom'
import tiger from './Assets/tiger.jpeg'
import { Helmet } from 'react-helmet-async'


export const Dashboard = () => {
  return (
    <>
      <Helmet>
        <title>Free AI Image Generator : Igen AI</title>
        
        <meta name="description" content="For all your tasks you can use our online AI picture generator igenai to find the best solutions that will satisfy your creative needs. igenai,Our AI-powered tool is perfect for everyone who creates visual content either a marketer, designer, or just a regular person. By means of this online free AI picture generator igenai you can easily express your ideas in striking visuals that attract people and create an effect with just a few clicks.igenai, our user-friendly interface makes it possible for everyone no matter the technical proficiency to harness the power of our online free AI image generator igenai.. IgenAi , our free AI image generator portrays innumerable styles to satisfy the requirements of your project ranging from animated graphics to realistic photos." />
      </Helmet>
      <div className='container'>
        <div className='heading'>
          <h2>Free</h2>
          <h1>AI Image<span className='ge'> Generator</span> and <span className='to'>Tools</span></h1>
          <p >Elevate your creations with the revolutionary Text to Image AI image generator,<br></br>
            and take your designs to the next level all free.

          </p>
        </div>
        <div className='image'>
          <h1>AI Image Generator</h1>
          <Link to="/Texttoimage" className='btns'>
            Generate
          </Link>
          


        </div>

        <div className='gif'>
          <h1>GIF Search</h1>
          <Link to="/" className='btnss'>
            Coming Soon
          </Link>


        </div>

      </div>
      
      

      {/* Information page */}

      <div className='info'>
        <div className='i-g'>

          <h2>IMAGE GENERATION</h2>
          <p>For all your tasks you can use our online AI picture generator igenAI to find the best solutions that will satisfy your creative needs. Our AI-powered tool is perfect for everyone who creates visual content either a marketer, designer, or just a regular person. By means of this online free AI picture generator you can easily express your ideas in striking visuals that attract people and create an effect with just a few clicks.
          </p>
          <button className='i-g-btn'>Try Image Generation</button>

        </div>
        <div className='image-pic'>
          <img className='tiger' src={tiger} alt="generation"></img>
        </div>
        
      </div>
      
      
      

      {/* <div className='info2'>
        <div className='gif-pic'>
          <img src={clock} alt="Gif search" />
        </div>
        <div className='g-g'>
          <h2>GIF SEARCH</h2>
          <p>Elevate your creations with the revolutionary Text to Image AI image generator,<br></br> revolutionizing the way you convert simple text into visually captivating artwork.<br></br> Unleash your imagination and craft breathtaking, AI-generated masterpieces<br></br> that are bound to captivate and inspire your audience.</p>
          <button className='g-g-btn'>Try Gif Search</button>
        </div>
      </div> */}

    </>
  )
}

