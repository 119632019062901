import React from 'react';
import { Helmet } from 'react-helmet-async';
import './Terms.css';

export const Terms = () => {
    return (
        <div>
            <Helmet>
                <title>Terms of Service : igenai.quantumorion.com</title>

                <meta name="description" content="Welcome to IgenAI.These Terms of Service govern your use of our website IgenAI, our AI image generation services, and other services we provide." />
            </Helmet>
            <div className='terms'>
                <div className='information'>
                    <h2>Terms of Service</h2>
                    <p>Welcome to IgenAI.These Terms of Service govern your use of our website IgenAI, our AI image generation services, and other services we provide.</p>
                </div>

                <div className='terms-content'>
                    <h3>Acceptance of Terms</h3>
                    <p>By accessing or using our Services, you agree to be bound by these Terms and our Privacy Policy. If you do not agree to these Terms, do not use our Services.</p>
                    
                    <h3>Changes to Terms</h3>
                    <p>We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on our Site. You are advised to review these Terms periodically for any changes.</p>
                    
                    <h3>Use of Our Services</h3>
                    <p>You agree to use our Services only for lawful purposes and in accordance with these Terms. You must not use our Services to generate content that:</p>
                    <ul>
                        <li>Infringes upon intellectual property rights or any other rights of others;</li>
                        <li>Is unlawful, harmful, fraudulent, or misleading;</li>
                        <li>Contains viruses or any other harmful code;</li>
                        <li>Violates any applicable laws or regulations.</li>
                    </ul>

                    <h3>Intellectual Property</h3>
                    <p>All content and materials on our Site, including images, text, software, and logos, are the property of igenai or its licensors and are protected by intellectual property laws. You may not use our intellectual property without our express written permission.</p>

                    <h3>Accounts and Security</h3>
                    <p>When you create an account with us, you agree to provide accurate, current, and complete information. You are responsible for maintaining the confidentiality of your account and password and for all activities that occur under your account.</p>

                    <h3>Limitation of Liability</h3>
                    <p>To the fullest extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use our Services; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; or (iii) any other matter relating to our Services.</p>

                    <h3>Indemnification</h3>
                    <p>You agree to defend, indemnify, and hold harmless igenai, its affiliates, and their respective officers, directors, employees, agents, and contractors from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your use of the Services or your violation of these Terms.</p>

                    <h3>Governing Law</h3>
                    <p>These Terms shall be governed by and construed in accordance with the laws of our country, without regard to its conflict of law principles.</p>

                    <h3>Contact Us</h3>
                    <p><span className='last'>If you have any questions about these Terms, please contact us at theorioncompanyinc@gmail.com</span></p>
                </div>
            </div>
        </div>
    );
};

export default Terms;
