import React from 'react'
import './About.css'
import { Helmet } from 'react-helmet-async';
export const About = () => {
  return (
    <div>
        <Helmet>
          <title>About us : igenai.quantumorion.com</title>
          <meta name="description" content="Welcome to IgenAI, we serve services like AI tools and many more. We started in 2024,we have been dedicated to providing serives to the people. At Igenai,our mission is to provide services based on AI like providing free AI images.We help people to generate images for their blogs,websites or any other purposes through our website.Our dedication towards the work drives everything we do,ensuring that we make a positive impact on the people who make use of this service."/>
        </Helmet>
    <div className='aboutus'>
      <div className='welcome'>
        <h2>About us</h2>
       <p>Welcome to IgenAI, we serve services like AI tools and many more. We started in 2024,we have been dedicated to providing serives to the people. At IgenAI,our mission is to provide services based on AI like providing free AI images.We help people  to generate images for their blogs,websites or any other purposes through our website.Our dedication towards the work drives everything we do,ensuring that we make a positive impact on the people who make use of this service.</p>

      </div>

      <div className='who-what-do'>
          <h4>Who We Are</h4>
          <p>As a group of driven professionals, we individually bring special talents and insights to the table.
          We are able to approach IT/AI-related problems from a variety of angles because of our varied experiences and areas of competence.</p>
          <h4>What We Do</h4>
          <p>At Igenai, we specialize in providing the services to the users. Whether it's
          service of providing AI generated images, we are committed to delivering quality in every project we undertake. From our honest work, we tailor our solutions to meet the unique needs of each client, ensuringtheir success and satisfaction.</p>
      </div>

      
    </div>
    </div>
  )
}
