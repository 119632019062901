import React, { useState, useRef } from 'react';
import './Gifsearch.css';
import am from './Assets/Poweredby_100px-Black_VertText.png';
import { Helmet } from 'react-helmet-async';

export const Gifsearch = () => {
  const[searhTerm, setSearchTerm] = useState('');
  const[gifs, setGifs] = useState([]);
  const[loading, setLoading] = useState(false);
  const inputRef = useRef(null);
   
  const apiKey = process.env.REACT_APP_GIFSEARCH_KEY;

  const gifGenerator = async() => {
     const prompt = inputRef.current.value.trim();
     if (prompt === ''){
      return;
     }

     setLoading(true);
  const gifCount = 10;
  const finalURL = `https://api.giphy.com/v1/gifs/search?api_key=${apiKey}&q=${prompt}&limit=${gifCount}&offset=0&rating=g&lang=en`;

  try{
    const response = await fetch(finalURL);
    const data = await response.json();
    setGifs(data.data);

  } catch(error){
    console.error('Error fetching GIF:',error)
  } finally{
    setLoading(false);
  }
  };

  const copyToClipboard = (gifId) => {
    const copylink = `https://media4.giphy.com/media/${gifId}/giphy.mp4`;
    navigator.clipboard.writeText(copylink).then(
      () => {
        alert('GIF copied to clipboard');
      },
      () => {
        alert('GIF copied to clipboard');

        const hiddenInput = document.createElement('input');
        hiddenInput.setAttribute('type', 'text');
        document.body.appendChild(hiddenInput);
        hiddenInput.value = copylink;
        hiddenInput.select();
        document.execCommand('copy');
        document.body.removeChild(hiddenInput); 
      }
    );
  };




  return (
    <>
    <Helmet>
          <title>Gif Search</title>
          
          <meta name="Gif Search" content="Gif Search"/>
        </Helmet>
    <div className='gifsearch'>
      <div className='headers'>
        GIF <span>Search</span>
      </div>
      <div className='search-boxs'>
        <input
          type='text'
          ref={inputRef}
          className='search-inputs'
          placeholder='Describe what you want to see'
        />
        <div className='generate-bt' onClick={gifGenerator}>
          Generate
        </div>
      </div>
      {loading ? (
        <div className='loader'>Loading...</div>
      ) : (
        <div className='wrapper'>
          {gifs.map((gif) => (
            <div className='containers' key={gif.id}>
              <img src={gif.images.downsized_medium.url} alt={gif.title} />
              <button className='copy-link' onClick={() => copyToClipboard(gif.id)}>Copy Link</button>
              
            </div>
            
          ))}
          
          {gifs.length > 0 && <img className='at' src={am} alt='powered by giphy' />}
        </div>
      )}


    </div>
    </>
  );
};

export default Gifsearch;
